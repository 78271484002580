.auth {
  width: 100%;
  min-height: 100vh;
  background-image: url("../../../assets/images/login-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.auth .auth-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.auth-contacts {
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.5);
  padding: 10px 20px;
  border-radius: 15px;
  backdrop-filter: blur(2px);
}

.auth-form {
  background-color: white;
  border-radius: 30px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
}

.auth-form-header {
  margin-bottom: 50px;
}
